import React from "react"
import Layout from "../shared/layout/layout"
import Seo from "../shared/seo/seo"
import "../styles/portfolio.scss"
import { Link } from "@reach/router"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { graphql, useStaticQuery } from "gatsby"
import private_house from "../images/thumb_houses.jpg"
import shops_restaurant from "../images/thumb_shops.jpg"
import hotels from "../images/thumb_hotel.jpg"

const ProjectPage = () => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          projects {
            title
            hotels {
              link
              label
            }
            private_house {
              label
              link
            }
            shops_restaurants {
              link
              label
            }
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <Seo title={data.site.siteMetadata.projects.title}></Seo>
      <div className=" container project-container">
        <div className=" row ">
          <div className="col-container col-xl-4 col-lg-4 col-md-12">
            <Link to={data.site.siteMetadata.projects.private_house.link}>
              <div
                className="d-flex 
              flex-column align-items-center"
              >
                <div className="square-item">
                  <LazyLoadImage
                    effect="blur"
                    className="img-fluid img-detail"
                    src={private_house}
                  ></LazyLoadImage>
                </div>
                <span>
                  {data.site.siteMetadata.projects.private_house.label}
                </span>
              </div>
            </Link>
          </div>

          <div className="col-container col-xl-4 col-lg-4 col-md-12">
            <Link
              to={data.site.siteMetadata.projects.shops_restaurants.link}
              state={{ path: "shop-restaurant" }}
            >
              <div className="d-flex flex-column align-items-center">
                <div className="square-item">
                  <LazyLoadImage
                    effect="blur"
                    className="img-fluid img-detail"
                    src={shops_restaurant}
                  ></LazyLoadImage>
                </div>
                <span>
                  {data.site.siteMetadata.projects.shops_restaurants.label}
                </span>
              </div>
            </Link>
          </div>

          <div className="col-container col-xl-4 col-lg-4 col-md-12">
            <Link
              to={data.site.siteMetadata.projects.hotels.link}
              state={{ path: "hotels" }}
            >
              <div className="d-flex flex-column align-items-center">
                <div className="square-item">
                  <LazyLoadImage
                    effect="blur"
                    className="img-fluid img-detail"
                    src={hotels}
                  ></LazyLoadImage>
                </div>
                <span>{data.site.siteMetadata.projects.hotels.label}</span>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ProjectPage
